var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// General
import { CompassAuth, } from "compass-shared-services";
import { HttpClient } from "compass-shared-services/src/interceptors";
import { getUrl } from "compass-commons";
const { USER_MANAGER_SERVICE_PATH, SERVER_URL, NAMESPACE } = appConfig;
const compassAuth = CompassAuth(SERVER_URL, NAMESPACE);
const URL_PATHS = {
    GET_USER_USERGROUPS: `${USER_MANAGER_SERVICE_PATH}/manager/user-groups/logged-user`,
    GET_USER_CUSTOMERS: `${USER_MANAGER_SERVICE_PATH}/manager/users/(userId)/customers`,
    SET_USER_CUSTOMERS: `${USER_MANAGER_SERVICE_PATH}/manager/users/(userId)/switch-organization`,
    GET_USER_INFO: `${USER_MANAGER_SERVICE_PATH}/manager/users/(userId)`,
};
const httpClient = new HttpClient({
    SERVER_URL,
    NAMESPACE,
});
class UserManagerService {
    static getUserLoggedAllowedModules() {
        return __awaiter(this, void 0, void 0, function* () {
            const urlPath = URL_PATHS.GET_USER_USERGROUPS;
            return compassAuth
                .userGroupToModulesAllowed(urlPath)
                .then((response) => {
                return response;
            })
                .catch((error) => __awaiter(this, void 0, void 0, function* () {
                throw Error(JSON.stringify(error));
            }));
        });
    }
    static getCustomers(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = {
                userId,
            };
            const urlPath = getUrl(URL_PATHS.GET_USER_CUSTOMERS, uri);
            return httpClient
                .get({
                url: urlPath,
            })
                .then((response) => {
                return response;
            })
                .catch((error) => __awaiter(this, void 0, void 0, function* () {
                throw Error(JSON.stringify(error));
            }));
        });
    }
    static getUserInfo(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = {
                userId,
            };
            const urlPath = getUrl(URL_PATHS.GET_USER_INFO, uri);
            return httpClient
                .get({
                url: urlPath,
            })
                .then((response) => {
                return response;
            })
                .catch((error) => __awaiter(this, void 0, void 0, function* () {
                throw Error(JSON.stringify(error));
            }));
        });
    }
    static setCustomer(customerId, userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const uri = {
                userId,
            };
            const urlPath = getUrl(URL_PATHS.SET_USER_CUSTOMERS, uri);
            return httpClient.put({
                url: urlPath,
                payload: JSON.stringify({
                    customerId,
                }),
            });
        });
    }
}
export default UserManagerService;

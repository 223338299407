import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import { Menu, MenuItem } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    menuItem: {
        backgroundColor: ({ isSubMenuOpen }) => { var _a; return isSubMenuOpen ? (_a = theme.palette) === null || _a === void 0 ? void 0 : _a.action.hover : "transparent"; },
        minWidth: "12rem",
        padding: 0,
    },
    contentContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flex: 1,
    },
    name: {
        display: "inline-block",
        padding: "6px 16px",
    },
    expandIcon: {
        fontSize: 12,
        marginRight: 6,
    },
}));
/* eslint-disable react/prop-types */
const NestedMenuItem = React.forwardRef(({ id: parentId, name: parentName, childrenItems: parentChildrenItems = [], onClick, url, selectedOption, }, ref) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isSubMenuOpen = Boolean(anchorEl);
    const classes = useStyles({ isSubMenuOpen });
    const hasChildrenItems = (parentChildrenItems === null || parentChildrenItems === void 0 ? void 0 : parentChildrenItems.length) || false;
    const isLeafNode = !hasChildrenItems;
    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        event.stopPropagation();
        if (isLeafNode) {
            onClick(parentId);
        }
    };
    return (_jsxs(MenuItem, Object.assign({ ref: ref, disableRipple: true, className: classes.menuItem, onClick: handleClick, onMouseEnter: handleMouseEnter, onMouseLeave: handleClose }, { children: [_jsxs("div", Object.assign({ className: classes.contentContainer }, { children: [url != null ? (_jsx(Link, Object.assign({ target: "_blank", rel: "noopener noreferrer", id: parentId, to: url, onClick: handleClose, className: `link${(selectedOption.startsWith(parentId) && " link-selected") || ""}` }, { children: _jsx("span", Object.assign({ className: classes.name }, { children: parentName }), void 0) }), void 0)) : (_jsx("span", Object.assign({ className: `${classes.name} ${(selectedOption === parentId && " link-selected") || ""}` }, { children: parentName }), void 0)), hasChildrenItems && (_jsx(ArrowForwardIosIcon, { className: classes.expandIcon }, void 0))] }), void 0), hasChildrenItems && (_jsx(_Fragment, { children: _jsx(Menu
                // "pointerEvents: none" to prevent invisible Popover wrapper div to capture mouse events
                , Object.assign({ 
                    // "pointerEvents: none" to prevent invisible Popover wrapper div to capture mouse events
                    style: { pointerEvents: "none" }, anchorEl: anchorEl, open: isSubMenuOpen, anchorOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    }, transformOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    }, PaperProps: {
                        elevation: 4,
                    } }, { children: _jsx("div", Object.assign({ style: { pointerEvents: "auto" } }, { children: parentChildrenItems.map((item, index) => {
                            const { code, name, children, urlString } = item;
                            return (_jsx(NestedMenuItem, { id: code, name: name, childrenItems: children, onClick: (id) => onClick(id), url: urlString, selectedOption: selectedOption }, index));
                        }) }), void 0) }), void 0) }, void 0))] }), void 0));
});
export default NestedMenuItem;

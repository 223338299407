import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import { withI18n, getLocalStorage, localStorageIdentifiers, CustomEventsIdentifiers, setLocalStorage, useGetToken, } from "compass-commons";
// Service
import UserManagerService from "./services/UserManagerService";
// Components
import HeaderNavBar from "./components/headerNavBar/HeaderNavBar";
const App = () => {
    const token = useGetToken();
    const isLoggedInUser = !!token;
    const [allowedModules, setAllowedModules] = useState([]);
    const [customers, setCustomers] = useState(null);
    const [user, setUser] = useState(null);
    const loadUserGroups = () => UserManagerService.getUserLoggedAllowedModules().then((ug) => {
        setAllowedModules(ug);
    });
    const loadUserInfo = () => {
        UserManagerService.getCustomers(getLocalStorage(localStorageIdentifiers.EXTENSION_USER_ID, appConfig.NAMESPACE)).then((customersData) => {
            setCustomers(customersData);
        });
        UserManagerService.getUserInfo(getLocalStorage(localStorageIdentifiers.EXTENSION_USER_ID, appConfig.NAMESPACE)).then((response) => {
            if (response)
                setUser(response);
        });
    };
    const tryLoadUserInfo = () => {
        const localStorageValue = getLocalStorage(localStorageIdentifiers.EXTENSION_USER_ID, appConfig.NAMESPACE);
        if (localStorageValue !== null) {
            try {
                loadUserInfo();
                return;
            }
            catch (error) {
                // do nothing
            }
        }
        setTimeout(tryLoadUserInfo, 500);
    };
    const tokenUpdateHandler = (_evt) => {
        loadUserGroups();
        setLocalStorage(localStorageIdentifiers.ALL_TABS_SYNC, "", {
            namespace: appConfig.NAMESPACE,
        });
        tryLoadUserInfo();
    };
    useEffect(() => {
        if (isStandalone) {
            tokenUpdateHandler(null);
        }
        window.addEventListener(CustomEventsIdentifiers.EVENT_TOKEN, tokenUpdateHandler);
        return () => {
            window.removeEventListener(CustomEventsIdentifiers.EVENT_TOKEN, tokenUpdateHandler);
        };
    }, []);
    return (_jsx("div", Object.assign({ "data-cr": "header-root" }, { children: (isLoggedInUser || isStandalone) && (_jsx(HeaderNavBar, { allowedModules: allowedModules, user: user, customers: customers }, void 0)) }), void 0));
};
export default withI18n(App);
